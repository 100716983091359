import { useEffect, useState } from "react"
import config from "react-global-configuration"
import { LocalAudioTrack, LocalTrack, LocalVideoTrack } from "twilio-video"

import { Button } from "components/Button"
import { Buttons, Card } from "components/Card/Card"
import PreviousButton from "components/Previous/PreviousButton"
import { Information } from "components/Title/Title"
import { createUserLocalTracks } from "containers/Call/utils"
import { cdn } from "core/cdn"
import { languages } from "lib/languages"


const InfoCard: React.FC = () => {
  return (
    <div style={{ display: 'flex', justifyContent: "center", marginTop: "100px" }}>
      <div style={{
        display: 'flex',
        background: "#EFEFEF",
        borderRadius: '5px',
        padding: '16px',
        width: "970px",
        gap: "10px"
      }}>
        <div style={{
          color: '#45b0e5',
          fontSize: '32px',
          marginRight: '16px',
          lineHeight: '32px',
          width: "40px"
        }}>
          <img src={cdn("/icons/i_picto.svg")} alt="info" style={{ width: "100%" }} />
        </div>
        <div style={{ color: "#211452", fontWeight: 400, fontSize: "24px" }}>
          {languages.pharmacistsPlease}
          <ul style={{ margin: '8px 0 0 20px', padding: '0' }}>
            <li>{languages.authorizeAccess}</li>
            <li>{languages.disconnectAndReconnect}</li>
          </ul>
          <p style={{ marginTop: '8px' }}>
            {languages.ifYouNeedHelp}{' '}
            <span style={{ fontWeight: 'bold' }}>01 88 24 22 87</span>.
          </p>
        </div>
      </div>
    </div>
  );
};

interface DiagnosisProps {
  onFinish: () => void
  onStop: () => void
}
const STEPS = {
  INITIALIZING: {
    title: "",
    Content: (props: any) => <></>,
  },
  DEVICES_SUCCESS: {
    title: languages.diagnosis_title_success,
    Content: (props: any) => (
      <Buttons>
        <img
          width="140"
          src={cdn("/icons/camera_access_picto.svg")}
          alt="camera  ok !"
        />
        <img
          width="140"
          src={cdn("/icons/micro_access_picto.svg")}
          alt="micro ok !"
        />
      </Buttons>
    ),
  },
  WAITING_DEVICES_ACCESS: {
    title: languages.diagnosis_title_waiting_device,
    Content: (props: any) => (
      <>
        <Buttons>
          <img
            width="140"
            src={cdn("/icons/camera_picto.svg")}
            alt="camera unauthorized"
          />
          <img
            width="140"
            src={cdn("/icons/micro_picto.svg")}
            alt="micro unauthorized"
          />
          <Information text={languages.diagnosis_information_waiting_device} />
        </Buttons>
        <div style={{ marginTop: "30px" }} className="center">
          <img
            height="300"
            src={cdn("/images/waiting_devices.gif")}
            alt="waiting devices"
          />
        </div>
      </>
    ),
  },
  PERMISSION_DENIED: {
    title: languages.diagnosis_title_permission_denied,
    Content: (props: any) => (
      <>
        <Buttons>
          <img
            width="140"
            src={cdn("/icons/camera_denied_picto.svg")}
            alt="camera access denied"
          />
          <img
            width="140"
            src={cdn("/icons/micro_denied_picto.svg")}
            alt="micro access denied"
          />
          <Information
            text={languages.diagnosis_information_permission_denied}
          />
        </Buttons>
        <div style={{ marginTop: "30px" }} className="i-card">
          <img src={cdn("/icons/i_picto.svg")} alt="info" />
          {languages.diagnosis_contact_support}
          <b>{config.get("support")}</b>
        </div>
      </>
    ),
  },
  UNKNOWN_ERROR: {
    title: languages.diagnosis_title_unknown_error,
    Content: (props: any) => (
      <>
        <Buttons>
          <img
            width="140"
            src={cdn("/icons/camera_cross_picto.svg")}
            alt="camera error"
          />
          <img
            width="140"
            src={cdn("/icons/micro_cross_picto.svg")}
            alt="micro error"
          />
          <Information text={languages.diagnosis_information_unknown_error} />
        </Buttons>
        <InfoCard />
      </>
    ),
  },
}
export const Diagnosis = (props: DiagnosisProps) => {
  const [loading, setLoading] = useState(true)
  const [steps, setSteps] = useState(STEPS.WAITING_DEVICES_ACCESS)

  useEffect(() => {
    checkLocalDevices()
  }, [])

  const checkLocalDevices = () => {
    setLoading(true)
    setSteps(STEPS.WAITING_DEVICES_ACCESS)
    createUserLocalTracks()
      .then((e) => {
        e.forEach(
          (track: LocalVideoTrack | LocalAudioTrack) =>
            track.stop && track.stop()
        )
        props.onFinish()
      })
      .catch((e) => {
        setLoading(false)
        console.error(e, {
          route: "diagnosis::checkLocalDevices::createUserLocalTracks"
        })
        if (e.message.includes("denied")) setSteps(STEPS.PERMISSION_DENIED)
        else setSteps(STEPS.UNKNOWN_ERROR)
      })
  }
  return (
    <Card title={steps.title}>
      <PreviousButton onClick={props.onStop} />
      <steps.Content />
      <Buttons>
        <Button
          disabled={loading}
          loading={loading}
          type="primary"
          onClick={loading ? props.onFinish : checkLocalDevices}
        >
          {loading
            ? languages.diagnosis_authorization_on_going
            : languages.relaunchTest}
        </Button>
      </Buttons>
    </Card>
  )
}
